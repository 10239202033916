<template>
    <div class="h5-container">
        <BACKH5 :title="$t('Account.KYCCertification')" />
        <div class="main">
            <div class="box">
                <div class="title">{{$t('Account.UploadIDPhoto')}}</div>
                <div v-html="$t('Account.UploadIDPhotoTips')" class="desc"></div>
                <div class="img-item">
                    <div class="label">{{$t('Account.FrontPhoto')}}</div>
                    <el-upload class="upload-box" :action="action" :headers="headers" :limit="1" :show-file-list="false" :on-success="handleUpload1">
                        <div v-if="!form.pic1" class="upload">
                            {{$t('Account.FrontPhotoTips')}}
                        </div>
                    </el-upload>
                    <img v-if="form.pic1" class="upload-img" :src="form.pic1" />
                </div>
                <div class="img-item">
                    <div class="label">{{$t('Account.BackPhoto')}}</div>
                    <el-upload class="upload-box" :action="action" :headers="headers" :limit="1" :show-file-list="false" :on-success="handleUpload2">
                        <div v-if="!form.pic2" class="upload">
                            {{$t('Account.BackPhotoTips')}}
                        </div>
                    </el-upload>
                    <img v-if="form.pic2" class="upload-img" :src="form.pic2" />
                </div>
                <el-button :loading="loading" class="submit-btn" @click="submit">{{$t('Common.Confirm')}}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        upload_url
    } from '@/request/api.js'
    export default {
        components: {
            BACKH5: () => import('@/components/BackH5.vue')
        },
        data() {
            return {
                action: upload_url,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
                    lang: localStorage.getItem('lang') || 'en'
                },
                form: {
                    pic1: '',
                    pic2: ''
                },
                loading: false,
                isUpload: false
            }
        },
        created() {
            this.getUserInfo()
        },
        methods: {
            getUserInfo() {
                this.$api.user_info().then(res => {
                    this.form.pic1 = res.data.pic1 || ''
                    this.form.pic2 = res.data.pic2 || ''
                    if(res.data.pic1 && res.data.pic2) {
                        this.isUpload = false
                    } else {
                        this.isUpload = true
                    }
                })
            },
            
            handleUpload1(res) {
                this.form.pic1 = res.data.file
            },
            
            handleUpload2(res) {
                this.form.pic2 = res.data.file
            },
            
            submit() {
                if(this.isUpload) {
                    this.loading = true
                    this.$api.set_user_info(this.form).then(res => {
                        this.loading = false
                        this.$message({
                            message: res.message,
                            type: 'success',
                            duration: 1500
                        })
                        setTimeout(() => {
                            this.$router.go(-1)
                        },1000)
                    }).catch(err => {
                        this.loading = false
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .h5-container {
        background-color: #f6f6f6;

        .main {
            width: 100%;
            box-sizing: border-box;
            padding: 1.2rem 1.2rem 2rem;
            
            .box {
                width: 100%;
                box-sizing: border-box;
                padding: 2rem 1.2rem;
                background-color: #FFFFFF;
                
                .title {
                    width: 100%;
                    margin-bottom: 1.6rem;
                    font-size: 1.5rem;
                    font-weight: bold;
                    color: #000000;
                    text-align: center;
                    word-wrap: break-word;
                }
                
                .desc {
                    width: 100%;
                    margin-bottom: 1.6rem;
                    font-size: 1.3rem;
                    color: #6D6F7E;
                    text-align: center;
                    word-wrap: break-word;
                }
                
                .img-item {
                    width: 100%;
                    margin-bottom: 1.6rem;
                    
                    .label {
                        width: 100%;
                        margin-bottom: 0.8rem;
                        font-size: 1.3rem;
                        color: #6D6F7E;
                        text-align: center;
                        word-wrap: break-word;
                    }
                    
                    .upload-box {
                        display: flex;
                        justify-content: center;
                        width: 100%;
                    }
                    
                    /deep/ .el-upload {
                        width: 100%;
                    }
                    
                    .upload {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 16.5rem;
                        box-sizing: border-box;
                        padding: 0 2rem;
                        margin: 0 auto;
                        border-radius: 4px;
                        font-size: 1.5rem;
                        font-weight: bold;
                        color: #FFFFFF;
                        text-align: center;
                        word-wrap: break-word;
                        background-color: #000000;
                        cursor: pointer;
                    }
                    
                    .upload-img {
                        display: block;
                        width: 100%;
                    }
                }
                
                .submit-btn {
                    width: 100%;
                    height: 4.8rem;
                    box-sizing: border-box;
                    margin: 2rem 0 0;
                    padding: 0;
                    border: 0;
                    border-radius: 4px;
                    font-size: 1.3rem;
                    color: #FFFFFF;
                    text-align: center;
                    background-color: #F08834;
                    cursor: pointer;
                }
            }
        }
    }
</style>
